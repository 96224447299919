<template>
  <div
    class="agentBar"
    style="
      color: #fff;
      padding-left: 46px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
    "
  >
    <div style="display: flex; justify-content: space-between; width: 100%">
      <div style="display: flex">
        <div
          style="
            line-height: 22px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <div>
            <IconFont type="iconziyuan9"></IconFont>
            <span style="margin-left: 5px">坐席状态：</span
            >{{ statusMap[agentStatus] }}
          </div>
          <div>
            <IconFont type="iconziyuan2"></IconFont>
            <span style="margin-left: 5px">状态时长：</span>{{ timerString }}
          </div>
        </div>
        <div
          style="
            line-height: 22px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 20px;
            width: 200px;
          "
        >
          <div>坐席号码：{{ agentNo }}</div>
          <div>联系电话：{{ customerNo }}</div>
        </div>
        <div style="height: 64px; margin-left: 20px; display: flex">
          <div v-if="!loginBtnDisable" style="padding-top: 6px">
            <a-button
              :disabled="loginBtnDisable"
              style="margin: 0 5px 2px"
              shape="circle"
              title="登录"
              @click="handleAgentBarBtnClick('login')"
            >
              <a-icon type="login" />
            </a-button>
            <div style="text-align: center">登录</div>
          </div>
          <div v-if="!logoutBtnDisable" style="padding-top: 6px">
            <a-button
              :disabled="logoutBtnDisable"
              style="margin: 0 5px 2px"
              shape="circle"
              title="登出"
              @click="handleAgentBarBtnClick('logout')"
            >
              <a-icon type="logout" />
            </a-button>
            <div style="text-align: center">登出</div>
          </div>
          <div
            style="
              line-height: 64px;
              padding-right: 5px;
              display: flex;
              align-items: center;
              position: relative;
            "
          >
            <div v-click-outside="onClickDialOutside">
              <a-input
                ref="outNumInput"
                v-model="outNum"
                @focus="showDial = true"
                size="large"
                id="phoneNumInput"
                placeholder="请输入号码"
                style="width: 200px; margin-left: 5px"
              >
                <template v-slot:suffix>
                  <IconFont
                    type="iconicon8"
                    style="color: rgba(0, 0, 0, 0.45)"
                  ></IconFont>
                </template>
              </a-input>
              <div
                v-show="showDial"
                style="
                  width: 200px;
                  background: #fff;
                  position: absolute;
                  right: 0px;
                  top: 64px;
                  padding: 10px 12px;
                  border-radius: 0px 0px 4px 4px;
                  box-shadow: 0 2px 8px rgb(0 0 0 / 9%);
                "
              >
                <a-row>
                  <a-col
                    v-for="item in numList"
                    :span="8"
                    style="text-align: center; line-height: 50px"
                  >
                    <a-button
                      @click="handleDialBtnClick(item)"
                      shape="circle"
                      style="
                        background: rgba(228, 228, 230, 1);
                        font-weight: bold;
                      "
                      >{{ item }}</a-button
                    >
                  </a-col>
                  <a-col
                    :span="8"
                    :offset="8"
                    style="text-align: center; line-height: 50px"
                  >
                    <a-button
                      shape="circle"
                      type="primary"
                      @click="handleAgentBarBtnClick('makecall')"
                    >
                      <a-icon type="phone" />
                    </a-button>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
          <div style="padding-top: 6px">
            <a-button
              :disabled="callBtnDisable"
              style="margin: 0 5px 2px"
              shape="circle"
              title="呼叫"
              @click="handleAgentBarBtnClick('makecall')"
            >
              <IconFont type="iconicon5"></IconFont>
            </a-button>
            <div style="text-align: center">拨打</div>
          </div>
          <div style="padding-top: 6px">
            <a-button
              :disabled="answerBtnDisable"
              style="margin: 0 5px 2px"
              shape="circle"
              title="接听"
              @click="handleAgentBarBtnClick('answer')"
            >
              <IconFont type="iconicon5"></IconFont>
            </a-button>
            <div style="text-align: center">接听</div>
          </div>
          <div style="padding-top: 6px">
            <a-button
              :disabled="hangupBtnDisable"
              style="margin: 0 5px 2px"
              shape="circle"
              title="挂断"
              @click="handleAgentBarBtnClick('hangup')"
            >
              <IconFont type="iconicon7"></IconFont>
            </a-button>
            <div style="text-align: center">挂断</div>
          </div>
          <div style="padding-top: 6px">
            <a-button
              :disabled="holdBtnDisable"
              style="margin: 0 5px 2px"
              shape="circle"
              title="保持"
              @click="handleAgentBarBtnClick('hold')"
            >
              <IconFont type="iconicon_keep"></IconFont>
            </a-button>
            <div style="text-align: center">保持</div>
          </div>
          <div style="padding-top: 6px">
            <a-button
              :disabled="unholdBtnDisable"
              style="margin: 0 5px 2px"
              shape="circle"
              title="取消保持"
              @click="handleAgentBarBtnClick('unhold')"
            >
              <IconFont type="iconjie"></IconFont>
            </a-button>
            <div style="text-align: center">取消</div>
          </div>
          <div style="padding-top: 6px">
            <a-button
              :disabled="transferBtnDisable"
              style="margin: 0 5px 2px"
              shape="circle"
              title="转接"
              @click="showTransferDial = true"
            >
              <IconFont type="iconicon_turn"></IconFont>
            </a-button>
            <div style="text-align: center">转接</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-click-outside.capture="onClickTransDialOutside"
      v-show="showTransferDial"
      style="
        width: 200px;
        background: #fff;
        position: absolute;
        left: 550px;
        top: 64px;
        padding: 10px 12px;
        border-radius: 0px 0px 4px 4px;
        box-shadow: 0 2px 8px rgb(0 0 0 / 9%);
      "
    >
      <a-input ref="transNumInput" v-model="transNum" style="margin: 5px 0" />
      <a-row>
        <a-col
          v-for="(item, index) in numList"
          :span="8"
          :key="index"
          style="text-align: center; line-height: 50px"
        >
          <a-button
            @click="handleTransDialBtnClick(item)"
            shape="circle"
            style="background: rgba(228, 228, 230, 1); font-weight: bold"
            >{{ item }}</a-button
          >
        </a-col>
        <a-col
          :span="8"
          :offset="8"
          style="text-align: center; line-height: 50px"
        >
          <a-button
            shape="circle"
            type="primary"
            @click="handleAgentBarBtnClick('transfer')"
          >
            <IconFont type="iconicon13"></IconFont>
          </a-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { directive as clickOutside } from "v-click-outside-x";
import VConsole from "vconsole";
const vConsole = new VConsole();
export default {
  name: "AgentBar",
  directives: { clickOutside },
  data() {
    return {
      //初始化SDK所需要的配置
      config: {
        host: "voip.hori-gz.com",
        port: "7443",
        proto: true,
        extNo: "1001",
        extPwd: "asdfezfek1234",
        autoRegister: true,
        debug: true, //stunServer: 'stun.voipbuster.com',   //可自行修改使用stun服务器地址
        stateEventListener: this.stateEventListener,
      },
      //坐席分机号
      agentNo: "",
      //客户号码
      customerNo: "",
      //拨号弹窗
      showDial: false,
      //转接弹窗
      showTransferDial: false,
      //转接号码
      transNum: "",
      numList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"],
      agentStatus: "DISCONNECTED",
      statusMap: {
        CONNECTED: "已连接",
        DISCONNECTED: "网络断开",
        REGISTERED: "已注册",
        UNREGISTERED: "未注册",
        REGISTER_FAILED: "注册失败",
        IN_CALL: "通话中",
        INCOMING_CALL: "来电振铃",
        OUTGOING_CALL: "外呼振铃",
        HOLD: "保持中",
        CALL_END: "通话结束",
      },
      timer: null,
      timerString: "00:00:00",
      outNum: "",
    };
  },
  computed: {
    loginBtnDisable() {
      return !["CONNECTED", "UNREGISTERED", "REGISTER_FAILED"].includes(
        this.agentStatus
      );
    },
    logoutBtnDisable() {
      return !["REGISTERED", "CALL_END"].includes(this.agentStatus);
    },
    callBtnDisable() {
      return !["REGISTERED"].includes(this.agentStatus);
    },
    answerBtnDisable() {
      return !["INCOMING_CALL"].includes(this.agentStatus);
    },
    hangupBtnDisable() {
      return !["IN_CALL", "HOLD", "OUTGOING_CALL", "INCOMING_CALL"].includes(
        this.agentStatus
      );
    },
    holdBtnDisable() {
      return !["IN_CALL"].includes(this.agentStatus);
    },
    unholdBtnDisable() {
      return !["HOLD"].includes(this.agentStatus);
    },
    transferBtnDisable() {
      return !["IN_CALL"].includes(this.agentStatus);
    },
  },
  methods: {
    onClickDialOutside(event) {
      console.log(event);
      this.showDial = false;
    },
    onClickTransDialOutside(event) {
      this.showTransferDial = false;
    },
    handleAgentBarBtnClick(name) {
      if (name === "login") {
        this.login();
      } else if (name === "logout") {
        this.logout();
      } else if (name === "answer") {
        this.answer();
      } else if (name === "hangup") {
        this.hangup();
      } else if (name === "makecall") {
        //console.info("makecall number:" + this.outNum)
        this.makeCall(this.outNum);
      } else if (name === "hold") {
        this.hold();
      } else if (name === "unhold") {
        this.unhold();
      } else if (name === "transfer") {
        this.transfer(this.transNum);
      }
    },

    login() {
      Ctibar.register();
    },
    logout() {
      Ctibar.unregister();
    },
    makeCall(phone) {
      if (phone === "" || phone === undefined) {
        console.error("无效的号码，请重新输入！");
        return;
      }
      Ctibar.makecall(phone);
      // if (new RegExp(/^(\d{3}-{0,1}\d{8}|\d{4}-{0,1}\d{7,8}|1{0,1}\d{10}|10086|10010|10000|\d{0,4}114|\d{6}|(9)\d{4})$/).test(this.outNum)) {
      //   Ctibar.makecall(this.outNum);
      // } else {
      //   this.$message.warning('无效的号码，请重新输入！');
      // }
    },
    hold() {
      Ctibar.hold();
    },
    unhold() {
      Ctibar.unhold();
    },
    answer() {
      Ctibar.answer();
    },
    hangup() {
      Ctibar.hangup();
    },
    transfer(phone) {
      console.info("触发转接", phone);
      Ctibar.transfer(phone);
    },
    //外呼拨号盘
    handleDialBtnClick(val) {
      this.outNum += val;
      this.$refs.outNumInput.focus();
    },
    //转接拨号盘
    handleTransDialBtnClick(val) {
      this.transNum += val;
      this.$refs.transNumInput.focus();
    },

    //参数为时间差秒数，返回这两个时间差并格式化
    computeTimeDiff(diff) {
      diff = Math.round(diff / 1000);
      let hour = Math.floor(diff / 3600)
        .toString()
        .padStart(2, "0");
      let min = Math.floor((diff - hour * 3600) / 60)
        .toString()
        .padStart(2, "0");
      let sec = (diff % 60).toString().padStart(2, "0");
      return hour + ":" + min + ":" + sec;
    },
    //重置时间
    restoreTime(origin) {
      clearInterval(this.timer);
      this.timerString = "00:00:00";
      this.timer = setInterval(() => {
        this.timerString = this.computeTimeDiff(new Date().getTime() - origin);
      }, 1000);
    },
    //状态变更回调
    stateEventListener(event, data) {
      //debug使用
      console.log(
        "当前event为: " + event + ", 当前data为: " + JSON.stringify(data)
      );

      this.agentStatus = event;
      let origin = new Date().getTime();
      switch (event) {
        case "CONNECTED":
          this.agentNo = data.localAgent;
          this.restoreTime(origin);
          break;

        case "DISCONNECTED":
          this.restoreTime(origin);
          break;

        case "UNREGISTERED":
          this.restoreTime(origin);
          break;

        case "OUTGOING_CALL":
          this.customerNo = data.otherLegNumber;
          this.restoreTime(origin);
          break;

        case "INCOMING_CALL":
          this.customerNo = data.otherLegNumber;
          //播放来电振铃音
          this.playRingMedia();
          this.restoreTime(origin);
          break;

        case "IN_CALL":
          this.stopPlayRingMedia();
          this.restoreTime(origin);
          break;

        case "CALL_END":
          this.stopPlayRingMedia();
          //挂机铃声
          this.playHangupMedia();
          this.restoreTime(origin);
          break;

        default:
      }
    },

    //播放挂机铃声
    playHangupMedia() {
      const _this = this;
      var hangupAudio = document.getElementById("hangupMediaAudioId");
      if (!hangupAudio) {
        hangupAudio = document.createElement("audio");
        hangupAudio.id = "hangupMediaAudioId";
        hangupAudio.hidden = true;
        hangupAudio.src = "wav/hangup.wav";
        document.body.appendChild(hangupAudio);
      }
      hangupAudio.play();
    },
    //播放来电振铃
    playRingMedia() {
      const _this = this;
      _this.stopPlayRingMedia();

      var ringAudio = document.getElementById("ringMediaAudioId");
      if (!ringAudio) {
        ringAudio = document.createElement("audio");
        ringAudio.id = "ringMediaAudioId";
        ringAudio.hidden = true;
        ringAudio.src = "wav/ring.wav";
        ringAudio.loop = "loop";
        document.body.appendChild(ringAudio);
      }
      ringAudio.play();
    },
    //停止播放来电振铃
    stopPlayRingMedia() {
      const _this = this;
      var ringAudio = document.getElementById("ringMediaAudioId");
      if (ringAudio) {
        document.body.removeChild(ringAudio);
      }
    },
    //初始化方法
    init() {
      Ctibar.initSDK(this.config);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
#agentNoInput,
#phoneNumInput {
  border-radius: 16px;
}
#menuBtn.ant-btn-link {
  color: #fff;
  transition: 0.3s;
}
#menuBtn.ant-btn-link:hover {
  color: rgba(255, 255, 255, 0.8);
}
/*#menuBtn.ant-btn-link:hover{color:#40a9ff}*/
.agentBar >>> .ant-input {
  height: 50px;
}
</style>
